import React, { Component } from "react";
import Menu from "./components/Menu.js";
import "./style/styles.css";
import "./style/App.css"
import Content from "./shop/Content.js";
import CategoryList from "./shop/CategoryList.js";
import ConciseBasket from "./shop/ConciseBasket.js";
import Footer from "./pages/Footer.js";
import Brands from "./shop/Brands.js";
import { LanguageProvider } from './languages/Language.js';
import fetchDataFromAPI from "./fetch/useFetchDataFromAPI.js";
import useFetchCampaignPrice from "./fetch/useFetchCampaignPrice.js";
import useSendDataToAPI from "./shop/useSendDataToAPI.js";
import Toast from "./components/Toast.js";
import { TOAST_PROPERTIES } from './components/toastProperties.js';
import { URL as NEWURL } from './constants.js';
import { BRANDS as NEWBRANDS } from './constants.js';
import { VENDORID as NEWVENDORID } from './constants.js';
import ShowImpressum from "./pages/ShowImpressum.js";
import ShowDatenschutz from "./pages/ShowDatenschutz.js";
import ShowAGB from "./pages/ShowAGB.js";
import CookieBanner from "./pages/CookieBanner.js";
import ShowContact from "./pages/ShowContact.js";
import ShowAboutUs from "./pages/ShowAboutUs.js";
import ShowDetailedBasket from "./shop/ShowDetailedBasket.js";
import { createUpdateState } from './shop/updateStateUtils.js';
import Menu2 from "./components/Menu2.js";

//import { ReactComponent as PlayStoreLogo } from './playstore.svg';
//import PlayStoreLogo from './playstore.jpg';
//verursacht bug: cannot find method url
// import { json } from "express";
//rfc shortcut

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      cartItems: [],
      items: [],
      allCategories: JSON.parse(sessionStorage.getItem('allCategories')),
      allFavorites: [],
      exist: "9",
      itemAmount: 1,
      sidebarOpen: false,
      productWebCategory: "Home",
      productWebSubCategory: "Deals",
      value: null,
      token: sessionStorage.getItem('token'),
      list: [],
      autoDeleteTime: 2000,
      autoDelete: true,
      categories: [],
      filteredData: "",
      impressum: false,
      datenschutz: false,
      agb: false,
      contact: false,
      aboutUs: false,
      detailedBasket: false,
      isCategoryOpen: false,
      isCartOpen: false,
      sortingKey:'smart'
    }
  }

  sortingFunc = (value) =>{
    console.log(value)
    this.setState({sortingKey:value});



      let searchResults = this.state.items;

      if(value=="alphabetical"){
        searchResults.sort((a, b) => {
          if (a.itemName1 < b.itemName1) {
              return -1;
          }
          if (a.itemName1 > b.itemName1) {
              return 1;
          }
          return 0;
      });
      this.setState({ filteredData: searchResults });
      this.setState({ productWebCategory: "SearchResults" })

      }else if(value=="specialCode"){

        searchResults.sort((a, b) => {
          if (a.itemSpeCode1 < b.itemSpeCode1) {
              return -1;
          }
          if (a.itemSpeCode1 > b.itemSpeCode1) {
              return 1;
          }
          return 0;
      });
      this.setState({ filteredData: searchResults });
      this.setState({ productWebCategory: "SearchResults" })

      }else if(value=="smart"){

      this.setState({ productWebCategory: "Home" })
      }



  }

  toggleMenu = () => {

    this.setState((prevState) => ({ isCartOpen: false }));
    this.setState((prevState) => ({ isCategoryOpen: !prevState.isCategoryOpen }));
  };

  toggleCart = () => {
    this.setState((prevState) => ({ isCategoryOpen: false }));
    this.setState((prevState) => ({ isCartOpen: !prevState.isCartOpen }));
  };

  ShowImpressum = value => {
    this.setState({ impressum: value })
  }
  ShowDatenschutz = value => {
    this.setState({ datenschutz: value })
  }
  ShowAGB = value => {
    this.setState({ agb: value })
  }
  ShowContact = value => {
    this.setState({ contact: value })
  }
  ShowAboutUs = value => {
    this.setState({ aboutUs: value })
  }
  ShowDetailedBasket = value => {
    this.setState({ detailedBasket: value })
  }

  showToast = type => {
    const toastProperties = TOAST_PROPERTIES.find((toast) => toast.title.toLowerCase() === type.toLowerCase());
    this.setState({ list: [...this.state.list, toastProperties] }, () => console.log(this.state.list));
  }

  checkItems = (value) => {
    let i = false;
    while (i === false) {
      switch (value) {
        case 'Deals':
          if (this.state.allCampaigns?.length === 0)
            value = "New Products"
          else i = true
          console.log(this.state.allCampaigns)
          console.log(value)
          break;
        case 'New Products':
          console.log(this.state.allCampaigns)
          if (this.state.allNewItems?.length === 0)
            value = "Favorites"
          else i = true
          break;
        case 'Favorites':
          if (this.state.allFavorites?.length === 0)
            value = "Your Choice"
          else i = true
          break;
        case 'Your Choice':
          if (this.state.allSold?.length === 0)
            value = "Discover"
          else i = true
          break;
        case 'Discover':
          if (this.state.notSold?.length === 0)
            value = "Deals"
          else i = true
          break;
        default:
          break;
      }
    }

    return value
  }

  componentDidMount() {
    if (sessionStorage.getItem("items")) {
      this.onLoadSavedSlip()
        .then(() => {
          this.setState({
            productWebSubCategory: this.checkItems(this.state.productWebSubCategory)
          });
        })
        .catch((error) => {
          // Handle the error if the slip is not loaded
          console.error(error);
        });
    }

    if (this.state.token === null)
      this.onLogoutDeleteItemsAndCategories()

    if (!this.state.categories
      && !sessionStorage.getItem('allCategories')
      && sessionStorage.getItem('token'))
      fetchDataFromAPI(1306) //service for categories
        .then(json => {
          this.onGetCategories()
        })

    if (sessionStorage.getItem('allCategories')) {
      this.onGetCategories()
    }

    if (!sessionStorage.getItem('items') && sessionStorage.getItem('token'))
      fetchDataFromAPI(2015) //service for GetBasket AND! service for Items.Get.Customer.Catalog
        .then(json => {
          this.categorizeItems(json?.slipLines)
        }).then(() => this.setState({
          productWebSubCategory: this.checkItems(this.state.productWebSubCategory)
        }, () => console.log("hier"))


        )

    /*  if (this.state.basketitems) {
        const basketArray = Object.values(this.state.basketitems);
        console.log("basketArray")
      }*/
  }

  onLogoutDeleteItemsAndCategories = () => {
    this.setState({
      items: null,
      cartItems: null,
      allCategories: null,
      categories: null,
      productWebSubCategory: "Deals"
    })
  }

  onGetCategories = () => {
    const cat = JSON.parse(sessionStorage.getItem('allCategories'));
    this.setState({
      categories: [...new Set(cat?.map(item =>
        item.itemCategoryName))],
      allCategories: cat,
      isLoaded: true
    }
    )
  }

  onGetItems = async () => {
    try {
      await this.onLoadSavedSlip();
      const productWebSubCategory = this.checkItems(this.state.productWebSubCategory);
      this.setState({ productWebSubCategory });
    } catch (error) {
      console.error(error);
    }
  };

  onLoadSavedSlip = () => {
    return new Promise((resolve, reject) => {
      const newState = sessionStorage.getItem('items');

      if (newState) {
        const parsedState = JSON.parse(newState);
        this.categorizeItems(parsedState)
          .then(() => {
            resolve(); // Resolve the Promise when the slip is loaded and categorization is done
          })
          .catch((error) => {
            reject(error); // Reject the Promise if an error occurs during categorization
          });
      } else {
        reject('No saved slip found'); // Reject the Promise if no slip is found
      }
    });
  };







  onWait = () => {
    this.setState({
      items: null,
      cartItems: null
    })
  }

  executeTask = async (obj) => {
    const json = await useFetchCampaignPrice(obj)  //service for GetCampaignPrice)
    if (json?.resCode === 200)
      obj = {
        ...obj, itemAmount: 0,
        itemCampaignPrice: json.dataSet ? json.dataSet[0]?.CampaignPrice : 0,
        ErpDiscRate: json.dataSet ? 0 : parseInt(sessionStorage.getItem("discRate")),
      }
    return obj
  }

  executeAllTasks = async () => {
    return await Promise.all(this.state.items?.map(obj =>
      obj.itemAmount > 0
        ? obj.itemCampaign
          ? this.executeTask(obj)
          : obj = { ...obj, itemAmount: 0 }
        : obj))
  }

  onSlipDeleteAllLines = async () => {
    this.executeAllTasks().then(newState => {
      sessionStorage.setItem('items', JSON.stringify(newState));
      this.categorizeItems(newState)
    })
  }

  categorizeItems = (newState) => {
    return new Promise((resolve, reject) => {
      const updateState = createUpdateState(newState);
      this.setState(updateState, () => {
        resolve(); // Resolve the Promise after the state has been updated
      });
    });
  };


  onAddAllFavorites = (item) => {

    item.itemFavorite = 1;
    const data = useSendDataToAPI(1310, item, item.itemFavorite)//service for Favorites
    sessionStorage.setItem('items', JSON.stringify(this.state.items))

    this.setState(previousState => ({ allFavorites: [...previousState.allFavorites, item] }));
    this.showToast('Favorite');
  }

  onRemoveAllFavorites = (item) => {
    item.itemFavorite = 0;
    sessionStorage.setItem('items', JSON.stringify(this.state.items))

    useSendDataToAPI(1310, item, item.itemFavorite) //service for Favorites
      .then(json => {
        this.setState({
          data: json
        });
      })

    this.setState(prevState =>
    ({
      allFavorites: this.state.allFavorites
        .filter((x) =>
          x.itemCode !== item.itemCode)
    })
    );
  }

  onAdd = async (item) => {
    const exist = this.state.cartItems?.find(x => x.itemCode === item.itemCode);
    const existItem = this.state.items?.find(x => x.itemCode === item.itemCode);
    item.itemAmount = existItem.itemAmount + 1;
    const url = NEWURL;
    fetch(url, {
      method: "POST",
      //mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        "serviceId": 2021, //service for Slip.Lines.Post
        "token": sessionStorage.getItem('token'),
        "slipRef": parseInt(sessionStorage.getItem('slipRef')),
        "erpItemRef": item.itemRef,
        "erpItemUnitARef": item.itemUnitARef,
        "erpItemAmount": item.itemAmount,
        "erpItemPrice": item.itemPrice,
        "erpDiscRate": sessionStorage.getItem('discRate') ? parseFloat(sessionStorage.getItem('discRate')) : 0,
        "campaignPrice": item.itemCampaignPrice,
        "erpLineExpl": "",
        "vendorId": NEWVENDORID
      }),
    }).then(res => res.json())
      .then(async (json) => {
        if (json.resCode === 200) {
          this.setState(this.state.items?.map(x =>
            x.itemCode === item.itemCode
              ? (x.itemAmount = item.itemAmount)
              : x)
            , () => {

              item.itemCampaignPrice = json.dataSet[0]?.CampaignPrice;
              item.ErpDiscRate = json.dataSet[0]?.ErpDiscRate;

              this.setState({
                data: json
              })

              this.setState({

                allNewItems: this.state.items?.map(item =>
                  item.itemNew > 0 ? item : null
                ).filter((x) => x !== null),

                allCampaigns: this.state.items?.map(item =>
                  item.itemCampaign > 0 ? item : null
                ).filter((x) => x !== null),

                allSold: this.state.items?.map(item =>
                  item.itemSold > 0 ? item : null
                ).filter((x) => x !== null),

                notSold: this.state.items?.map(item =>
                  item.itemSold === 0 ? item : null
                ).filter((x) => x !== null),
              })
              sessionStorage.setItem('items', JSON.stringify(this.state.items))
            }
          );

          if (exist) {
            this.setState(this.state.cartItems?.map(x =>
              x.itemCode === item.itemCode
                ? (x.itemAmount = item.itemAmount)
                : x)
            )
          } else {
            item.itemAmount = 1;
            this.setState(previousState => ({ cartItems: [...previousState.cartItems, item] }));
          }
        } else console.log(json)
      }).catch((err) => {
        console.log(err);
      });

    // all items in the basket
    let counter = 0;
    this.state.cartItems?.forEach((cartItem) => {
      counter += cartItem.qty;
    })
  }

  onRemove = async (item) => {
    const exist = this.state.cartItems?.find(x => x.itemCode === item.itemCode);
    if (item.itemAmount > 0)
      item.itemAmount = exist.itemAmount - 1;

    const url = NEWURL;
    fetch(url, {
      method: "POST",
      //mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        "serviceId": 2021, //service for Slip.Lines.Post
        "token": sessionStorage.getItem('token'),
        "slipRef": parseInt(sessionStorage.getItem('slipRef')),
        "erpItemRef": item.itemRef,
        "erpItemUnitARef": item.itemUnitARef,
        "erpItemAmount": item.itemAmount ? item.itemAmount : 0,
        "erpItemPrice": item.itemPrice,
        "erpDiscRate": sessionStorage.getItem('discRate') ? parseFloat(sessionStorage.getItem('discRate')) : 0,
        "campaignPrice": item.itemCampaignPrice,
        "erpLineExpl": "",
        "vendorId": NEWVENDORID
      }),
    }).then(res => res.json())
      .then(async (json) => {
        if (json.resCode === 200) {
          item.itemCampaignPrice = json.dataSet[0]?.CampaignPrice;
          item.ErpDiscRate = json.dataSet[0]?.ErpDiscRate;
          sessionStorage.setItem("items", JSON.stringify(this.state.items))
          this.setState({
            data: json
          })

          this.setState(this.state.items?.map(x =>
            x.itemCode === item.itemCode
              ? (x.itemAmount = item.itemAmount)
              : x)
            , () => {

              this.setState({

                allNewItems: this.state.items?.map(item =>
                  item.itemNew > 0 ? item : null
                ).filter((x) => x !== null),

                allCampaigns: this.state.items?.map(item =>
                  item.itemCampaign > 0 ? item : null
                ).filter((x) => x !== null),

                allSold: this.state.items?.map(item =>
                  item.itemSold > 0 ? item : null
                ).filter((x) => x !== null),

                notSold: this.state.items?.map(item =>
                  item.itemSold === 0 ? item : null
                ).filter((x) => x !== null),
              })
              sessionStorage.setItem('items', JSON.stringify(this.state.items))
            }
          );
          //if(this.state.cartItems.length === 0) this.setState({ detailedBasket: false })
          if (item.itemAmount === 0) {
            this.setState(prevState => ({ cartItems: prevState.cartItems.filter((x) => x.itemCode !== item.itemCode) })
              , () => {
                if (this.state.cartItems.length === 0) this.setState({ detailedBasket: false });
              }
            );
            item.itemAmount = null;

          } else {
            this.setState(this.state.cartItems?.map(x => x.itemCode === item.itemCode ? x.itemAmount = item.itemAmount : x
            ));
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  handleViewCategorySidebar = () => {
    this.setState(({ sidebarOpen }) => ({
      sidebarOpen: !sidebarOpen
    }));
  };

  switchToNext(item, value) {
    if (item !== "PickedProducts" && item !== "Home") {
      return value;
    }

    let i = false;

    while (i === false) {
      console.log(item)
      if(!value) value = 'Deals'
      console.log(value)
      switch (value) {
        case 'Deals':
          if (this.state.allCampaigns.length === 0)
            value = "New Products"
          else i = true
          break;
        case 'New Products':
          if (this.state.allNewItems.length === 0)
            value = "Favorites"
          else i = true
          break;
        case 'Favorites':
          if (this.state.allFavorites.length === 0)
            value = "Your Choice"
          else i = true
          break;
        case 'Your Choice':
          if (this.state.allSold.length === 0)
            value = "Discover"
          else i = true
          break;
        case 'Discover':
          if (this.state.notSold.length === 0)
            value = "Deals"
          else i = true
          break;
        default:
          console.log(value)
          value = "Deals"
          break;
      }
    }
    return value
  }

  WebCategory = (item, x) => {
    console.log(item,x)
    window.scrollTo(0, 0)
    this.setState({ filteredData: "" });
    if (item)
      this.setState({ productWebCategory: item });

      this.setState({
        productWebSubCategory: this.switchToNext(item, x)
      }, () => console.log(this.state.productWebSubCategory));


     if (x === null)
       window.scrollTo(0, 0)

     const elemToScrollTo = document.getElementById(x)
     if (elemToScrollTo) {
       elemToScrollTo.scrollIntoView()
     }
     return this.switchToNext(item, x)
  };

  setAppToken = (data) => {
    this.setState({ token: data });
  };

  setItems = (e, item) => {

    if (e) {
      this.setState(this.state.items?.map(x =>
        x.itemCode === item.itemCode
          ? x.itemAmount = e
          : x)
        , () => {
          this.setState({

            allNewItems: this.state.items?.map(item =>
              item.itemNew > 0 ? item : null
            ).filter((x) => x !== null),

            allCampaigns: this.state.items?.map(item =>
              item.itemCampaign > 0 ? item : null
            ).filter((x) => x !== null),

            allSold: this.state.items?.map(item =>
              item.itemSold > 0 ? item : null
            ).filter((x) => x !== null),

            notSold: this.state.items?.map(item =>
              item.itemSold === 0 ? item : null
            ).filter((x) => x !== null),
          })
          sessionStorage.setItem('items', JSON.stringify(this.state.items))
        }
      );

      const exist = this.state.cartItems
        .find(x => x.itemCode === item.itemCode);
      if (exist) {
        this.setState(this.state.cartItems?.map(x => x.itemCode === item.itemCode
          ? x.itemAmount = e
          : x
        ));
      } else {
        item.itemAmount = e;
        this.setState(previousState =>
          ({ cartItems: [...previousState.cartItems, item] }));
      }
      let counter = 0;
      this.state.cartItems.forEach((cartItem) => {
        counter += cartItem.qty;
      })
    }
    else {
      this.setState(this.state.items?.map(x =>
        x.itemCode === item.itemCode
          ? x.itemAmount = e
          : x)
        , () => {

          this.setState({

            allNewItems: this.state.items?.map(item =>
              item.itemNew > 0 ? item : null
            ).filter((x) => x !== null),

            allCampaigns: this.state.items?.map(item =>
              item.itemCampaign > 0 ? item : null
            ).filter((x) => x !== null),

            allSold: this.state.items?.map(item =>
              item.itemSold > 0 ? item : null
            ).filter((x) => x !== null),

            notSold: this.state.items?.map(item =>
              item.itemSold === 0 ? item : null
            ).filter((x) => x !== null),
          })
           sessionStorage.setItem('items', JSON.stringify(this.state.items))
        }
      );

      const exist = this.state.cartItems
        .find((x) =>
          x.itemCode === item.itemCode
        );
      if (exist) {
        this.setState(prevState => ({
          cartItems: this.state.cartItems
            .filter((x) =>
              x.itemCode !== item.itemCode
            )
        })
          , () => {
            if (this.state.cartItems.length === 0) this.setState({ detailedBasket: false });

          }


        );
        item.itemAmount = null;
      }
    }
  }

  handleChangeCount = (event, item) => {
    const e = event.target.valueAsNumber;
    const url = NEWURL;

    fetch(url, {
      method: "POST",
      //mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        "serviceId": 2021, //service for Slip.Lines.Post
        "token": sessionStorage.getItem('token'),
        "slipRef": parseInt(sessionStorage.getItem('slipRef')),
        "erpItemRef": item.itemRef,
        "erpItemUnitARef": item.itemUnitARef,
        "erpItemAmount": e ? e : 0,
        "erpItemPrice": item.itemPrice,
        "erpDiscRate": sessionStorage.getItem('discRate')
          ? parseFloat(sessionStorage.getItem('discRate'))
          : 0,
        "campaignPrice": item.itemCampaignPrice,
        "erpLineExpl": "",
        "vendorId": NEWVENDORID
      }),
    }).then(res => res.json())
      .then(json => {
        if (json.resCode === 200) {
          item.itemCampaignPrice = json.dataSet[0]?.CampaignPrice;
          item.ErpDiscRate = json.dataSet[0]?.ErpDiscRate;
          this.setItems(e, item)
          sessionStorage.setItem("items", JSON.stringify(this.state.items))
          this.setState({
            data: json
          })
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  search = (value) => {

    if (value) {
      let searchResults = this.state.items.filter(item => {
        return (item.itemName1.toLowerCase().includes(value.toLowerCase())
          || item.itemName2.toLowerCase().includes(value.toLowerCase())
          || item.itemCode.includes(value)
          || item.itemCategoryName.toLowerCase().includes(value.toLowerCase())
          || item.itemSubCategoryName.toLowerCase().includes(value.toLowerCase())
        )
      })
      this.setState({ filteredData: searchResults });
      this.setState({ productWebCategory: "SearchResults" })
    }
    if (value === '') {
      this.setState({ filteredData: this.state.items });
    }
  }

  render() {
    return (
      <LanguageProvider>
        <div
          className="h-screen w-screen overflow-x-hidden"
        >
          {/* Header */}
          <header
            className="sticky top-0 z-40 h-14 flex w-full "
          >
            {!sessionStorage.getItem("token")?<Menu2
            ShowImpressum={(value) => this.ShowImpressum(value)}
            ShowDatenschutz={(value) => this.ShowDatenschutz(value)}
            ShowAGB={(value) => this.ShowAGB(value)}
            ShowContact={(value) => this.ShowContact(value)}
            ShowAboutUs={(value) => this.ShowAboutUs(value)}
            WebCategory={this.WebCategory}
            onClick={() => this.handleViewCategorySidebar()}
            setAppToken={this.setAppToken}
            search={this.search}
            onLoadSavedSlip={() => this.onLoadSavedSlip()}
            onWait={this.onWait}
            onGetItems={() => this.onGetItems()}
            onGetCategories={() => this.onGetCategories()}
            onLogoutDeleteItemsAndCategories={() => this.onLogoutDeleteItemsAndCategories()}
            />:
            <Menu
            ShowImpressum={(value) => this.ShowImpressum(value)}
            ShowDatenschutz={(value) => this.ShowDatenschutz(value)}
            ShowAGB={(value) => this.ShowAGB(value)}
            ShowContact={(value) => this.ShowContact(value)}
            ShowAboutUs={(value) => this.ShowAboutUs(value)}
              cartCount = {this.state.cartItems ? this.state.cartItems.length : 0}
              isCategoryOpen = {this.state.isCategoryOpen}
              toggleMenu ={() => this.toggleMenu()}
              toggleCart ={() => this.toggleCart()}
              WebCategory={this.WebCategory}
              onClick={() => this.handleViewCategorySidebar()}
              setAppToken={this.setAppToken}
              search={this.search}
              onLoadSavedSlip={() => this.onLoadSavedSlip()}
              onWait={this.onWait}
              onGetItems={() => this.onGetItems()}
              onGetCategories={() => this.onGetCategories()}
              onLogoutDeleteItemsAndCategories={() => this.onLogoutDeleteItemsAndCategories()}
            />}
          </header >

          {/* Main */}
          {
            !sessionStorage.getItem("token")
              ? <main className=" relative px-4 py-2 flex  w-full h-full ">
                <iframe className="responsive-iframe" src="pages/index.html" title="LandingPage"></iframe>
              </main>
              : <main
                className="relative px-2 flex w-full md:h-[calc(100%-7rem)]"
              >
                <div
                  className="relative flex flex-col h-full w-full "
                >
                  <div
                    className={`${this.state.productWebCategory !== "Home"
                      ? "h-full w-full "
                      : !NEWBRANDS
                        ? "h-full w-full"
                        : ""} flex flex-row w-full`
                    }
                  >
                    <CategoryList

                      productWebCategory={this.state.productWebCategory}
                      WebCategory={this.WebCategory}
                      allCategories={this.state.allCategories}
                      itemCategoryName={this.state.categories}
                      existCampaigns={this.state.allCampaigns?.length}
                      existNewItems={this.state.allNewItems?.length}
                      existFavorites={this.state.allFavorites?.length}
                      existSoldItems={this.state.allSold?.length}
                      existNotSoldItems={this.state.notSold?.length}
                      isCategoryOpen = {this.state.isCategoryOpen}
                      isCartOpen = {this.state.isCartOpen}
                      toggleMenu ={() => this.toggleMenu()}
                      toggleCart ={() => this.toggleCart()}
                    />

                    <Content
                      isCategoryOpen = {this.state.isCategoryOpen}
                      isCartOpen = {this.state.isCartOpen}
                      items={this.state.items}
                      allFavorites={this.state.allFavorites}
                      allCampaigns={this.state.allCampaigns}
                      allNewItems={this.state.allNewItems}
                      allSold={this.state.allSold}
                      itemSubCategoryName={this.state.itemSubCategoryName}
                      notSold={this.state.notSold}
                      onRemove={this.onRemove}
                      onAdd={this.onAdd}
                      handleChangeCount={this.handleChangeCount}
                      productWebCategory={this.state.productWebCategory}
                      productWebSubCategory={this.state.productWebSubCategory}
                      itemCategoryName={this.state.itemCategoryName}
                      allCategories={this.state.allCategories}
                      onAddAllFavorites={this.onAddAllFavorites}
                      onRemoveAllFavorites={this.onRemoveAllFavorites}
                      value={this.state.value}
                      filteredData={this.state.filteredData}

                      sortingKey ={this.state.sortingKey}
                      sortingFunc = {this.sortingFunc}
                    />
                    <ConciseBasket
                      onAdd={this.onAdd}
                      onRemove={this.onRemove}
                      cartItems={this.state.cartItems}
                      onAddAllFavorites={this.onAddAllFavorites}
                      onRemoveAllFavorites={this.onRemoveAllFavorites}
                      onSlipDeleteAllLines={() => this.onSlipDeleteAllLines()}
                      ShowDetailedBasket={(value) => this.ShowDetailedBasket(value)}
                      isCartOpen = {this.state.isCartOpen}
                      toggleCart ={() => this.toggleCart()}
                    />
                  </div>
                  {this.state.productWebCategory
                    && NEWBRANDS
                    && this.state.productWebCategory === "Home"
                    ?
                    <div
                      className="absolute flex flex-row h-24  inset-x-0 bottom-0"
                    >
                      <Brands />
                    </div>
                    : null
                  }
                </div>
              </main >
          }

          {/* Footer */}
          <footer
            className="fixed bottom-0 flex w-full h-14 items-center justify-center "
          >
            <Footer
              ShowImpressum={(value) => this.ShowImpressum(value)}
              ShowDatenschutz={(value) => this.ShowDatenschutz(value)}
              ShowAGB={(value) => this.ShowAGB(value)}
              ShowContact={(value) => this.ShowContact(value)}
              ShowAboutUs={(value) => this.ShowAboutUs(value)}
            />
          </footer>
        </div>

        <Toast
          toastList={this.state.list}
          autoDelete={this.state.autoDelete}
          autoDeleteTime={this.state.autoDeleteTime}
        />
        <ShowImpressum impressum={this.state.impressum} ShowImpressum={(value) => this.ShowImpressum(value)} />
        <ShowDatenschutz datenschutz={this.state.datenschutz} ShowDatenschutz={(value) => this.ShowDatenschutz(value)} />
        <ShowAGB agb={this.state.agb} ShowAGB={(value) => this.ShowAGB(value)} />
        <ShowContact contact={this.state.contact} ShowContact={(value) => this.ShowContact(value)} />
        <ShowAboutUs aboutUs={this.state.aboutUs} ShowAboutUs={(value) => this.ShowAboutUs(value)} />
        <ShowDetailedBasket
          showToast={(type) => this.showToast(type)}
          detailedBasket={this.state.detailedBasket}
          ShowDetailedBasket={(value) => this.ShowDetailedBasket(value)}
          onAddAllFavorites={this.onAddAllFavorites}
          onRemoveAllFavorites={this.onRemoveAllFavorites}
          cartItems={this.state.cartItems}
          //onClick={this.props.onClickIncrement}
          onAdd={(record) =>
            this.onAdd(record)
          }
          onRemove={(record) =>
            this.onRemove(record)
          }
          onSlipDeleteAllLines={() => this.onSlipDeleteAllLines()}
        />
        <CookieBanner ShowDatenschutz={(value) => this.ShowDatenschutz(value)} />

      </LanguageProvider>
    );
  }
}